import React from 'react';
import CardGeneralContainer from '../containers/CardGeneralContainer';
import CardClientBrandContainer from '../containers/CardClientBrandContainer';
import CardDSPScreensContainer from '../containers/CardDSPScreensContainer';
import CartInitialBudgetContainer from '../containers/CartInitialBudgetContainer';
import LeftSideStyleHolder from './Styles/leftSideStyle';
import { Button, Icon } from "antd";
import { useTranslation } from 'react-i18next';

const LeftSideComponent = ({stepsCurrent, updateByAmountBudget, cart, customContents, updateRealAmount, viewCampaignPayment, editCampaignActive, currentCampaign, editingCampaign, prevSteps, history}) => {

  const isButtonRendered = viewCampaignPayment || stepsCurrent !== 0 || !editCampaignActive || (currentCampaign && currentCampaign.payment_status && currentCampaign.payment_status !== "approved");

  const { t } = useTranslation(); 

  return (
    <LeftSideStyleHolder showButtonGoBack={isButtonRendered}>
        <div className="leftSideContainer">
        { viewCampaignPayment ? (
              <Button
                className="goBackButtons"
                onClick={() => history.push(`/map`)}
              >
                <Icon
                  style={{ fontSize: "18px"}}
                  type="left"
                />{" "}
                {t('Go to map')}
              </Button>
            ) :
              (stepsCurrent === 0 ? (
                <>
                  {(!editCampaignActive || (editCampaignActive && currentCampaign.payment_status !== "approved")) && (
                    <Button className="goBackButtons" onClick={prevSteps}>
                      {(!editCampaignActive ||
                        (currentCampaign || ["pending", "cancelled"].includes(currentCampaign.payment_status))) && (
                          <>
                            <Icon type="arrow-left" />
                            <span style={{ textTransform: "capitalize" }}></span>
                            {currentCampaign && ["pending", "cancelled"].includes(currentCampaign.payment_status) && editCampaignActive
                              ? t("Edit")
                              : t("Map")}
                          </>
                        )}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  className="goBackButtons"
                  disabled={editingCampaign}
                  onClick={prevSteps}
                >
                  <Icon type="arrow-left" />
                  <span style={{ textTransform: "capitalize" }}>{t("Go back")}{" "}</span>
                </Button>
              ))
            }
            {stepsCurrent !== 2 && (
                <>
                  <CartInitialBudgetContainer
                    stepsCurrent={stepsCurrent}
                    updateByAmountBudget={updateByAmountBudget}
                    cart={cart}
                    customContents={customContents}
                    updateRealAmount={updateRealAmount}
                  />
                  <CardGeneralContainer stepsCurrent={stepsCurrent} />
                  <CardClientBrandContainer stepsCurrent={stepsCurrent} />
                  <CardDSPScreensContainer stepsCurrent={stepsCurrent} />
                </>
            )}
        </div>
    </LeftSideStyleHolder>
  )
}

export default LeftSideComponent
