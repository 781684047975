import React from 'react';
import RightSideStyleHolder from './Styles/rightSideStyle';
import CardAverageCPMContainer from '../containers/CardAverageCPMContainer';
import CardAdsInfoContainer from '../containers/CardAdsInfoContainer';
import CardTotalSpotsContainer from '../containers/CardTotalSpotsContainer';
import CartBudgetContainer from '../containers/CartBudgetContainer';
import { Button, Icon } from "antd";
import { useTranslation } from 'react-i18next';

const RightSideComponent = ({ stepsCurrent, programmatic, listResolutionWithContent, updateByAmountBudget, cart, customContents, updateRealAmount, editCampaignActive, editingCampaign, editCampaign, nextSteps }) => {

  const { t } = useTranslation(); 

  return (
    <RightSideStyleHolder>
        <div className="rightSideContainer">
            {stepsCurrent === 1 && (
              editCampaignActive ? (
                <Button
                  className={`continueButtons ${editingCampaign ? 'continueButtonsEditCreate' : ''}`}
                  onClick={editCampaign}
                  loading={editingCampaign}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Edit")}{" "}
                  </span>
                  {!editingCampaign && (<Icon type="arrow-right" />)}
                </Button>
              ) : (
                <Button
                  className="continueButtons createButton"
                  onClick={nextSteps}>
                  <span style={{ textTransform: "capitalize" }}>
                    {t("Create campaign")}
                  </span>
                  <Icon className="createIcon" type="arrow-right" />
                </Button>
              )
            )}
            {stepsCurrent === 0 && (
              <Button className="continueButtons" onClick={nextSteps}>
                <span style={{ textTransform: "capitalize" }}>
                  {t("continue")}{" "}
                </span>
                <Icon type="arrow-right" />
              </Button>)
            }
            {stepsCurrent === 0 && (
                <>
                    {programmatic === "programmatic" && (
                        <CardAverageCPMContainer stepsCurrent={stepsCurrent} />
                    )}
                    <CardAdsInfoContainer listResolutionWithContent={listResolutionWithContent} />
                </>
            )}
            {stepsCurrent === 1 && (
                <>
                    <CartBudgetContainer
                        stepsCurrent={stepsCurrent}
                        updateByAmountBudget={updateByAmountBudget}
                        cart={cart}
                        customContents={customContents}
                        updateRealAmount={updateRealAmount}
                    />
                    {programmatic === "programmatic" && (
                        <CardAverageCPMContainer stepsCurrent={stepsCurrent} />
                    )}
                </>
            )}
            {(stepsCurrent === 1 && programmatic === "programmatic") && (
              <CardTotalSpotsContainer stepsCurrent={stepsCurrent} customContents={customContents}/>
            )}
        </div>
    </RightSideStyleHolder>
  )
}

export default RightSideComponent
