import React, { useState } from 'react';
import { Button, Col, Row, Select, Typography, Icon, Tooltip, Modal, Badge } from "antd";
import { useTranslation } from 'react-i18next';
import CampaignGroupFilterHolder from "./Styles/campaignGroupFilterStyle";
import RulesContainer from '../containers/RulesContainer';
const { Text } = Typography;

const ScreensGroupFilterComponent = (props) => {
  const [rulesModalVisible, setRulesModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();
  const {
    managerDisplayFilter,
    managerDisplaySelector,
    setManagerDisplaySelector,
    setManagerDisplayFilter,
    disabledManagerDisplayFilter,
    disableManagerDisplayFilter,
    isSuperUser,
    customContents,
    cart,
    realAmount,
    updateCustomContents,
    isPaymentStatusApproved,
    programmatic,
  } = props;


  const arrFilter = [
    {
      name: "aspect",
      label: "Aspect ratio",
    },
    {
      name: "dimensions",
      label: "General dimensions",
    },
    {
      name: "resolution",
      label: "Resolution",
    },
    {
      name: "screen",
      label: "Type of screen",
    },
    {
      name: "subtype",
      label: "Sub Type of screen",
    },
    {
      name: "none",
      label: "None",
    },
  ];

  const onClick = (filter) => {
    if (filter.name === managerDisplayFilter) return;
    if(filter.name ===  "none") setManagerDisplaySelector("")

    setManagerDisplayFilter(filter.name);
    disableManagerDisplayFilter(true);
  }

  const calculateRules = () => {
    if (!customContents.length || !Array.isArray(customContents)) return 0;
    const uniqueIds = new Set();
    customContents.forEach(content => {
      if (content.rules && Array.isArray(content.rules)) {
        content.rules.forEach(rule => {
          if (rule.configRules && rule.configRules.id) {
            uniqueIds.add(rule.configRules.id);
          }
        });
      }
    });
    return uniqueIds.size;
  };

  // Only for pending campaign
  const redistributeImpressionsEquitably = () => {

    const listCostDisplay = cart.reduce((acc, display) => {
      acc[display.id] = display.smart_campaign_cpm / 1000;
      return acc;
    }, {});

    const totalCostPerImpression = customContents.reduce((acc, customContent) => {
        return acc + (listCostDisplay[customContent.displayId] || 0);
    }, 0);

    let totalImpressions = Math.floor(realAmount / totalCostPerImpression);
    let assignedBudget = 0;
    let customContentsAux = customContents.map((customContent, index) => {
        assignedBudget = assignedBudget + totalImpressions;
        return { ...customContent, promised_shows: totalImpressions};
    });

    let remainingBudget = realAmount - assignedBudget;

    for (let i = 0; i < customContentsAux.length && remainingBudget > 0; i++) {
        const extraCost = customContentsAux[i].smart_campaign_cpm;
        if (remainingBudget >= extraCost) {
            customContentsAux[i].promised_shows += 1;
            remainingBudget = remainingBudget - extraCost;
        }
    }

    updateCustomContents(customContentsAux)
  }

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    redistributeImpressionsEquitably();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRulesChange = (value) => {
    setRulesModalVisible(!rulesModalVisible);
  };

  return (
    <CampaignGroupFilterHolder>
      <Row type="flex" align="middle" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="groupFilter">
          <div className="contentGroupFilter">
            <Text style={{ marginRight: "5px" }}>{t("Group by")}:</Text>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {arrFilter.map((filter) => (
                <Button
                  key={filter.name}
                  className="groupFilterButton"
                  type={managerDisplayFilter === filter.name ? "primary" : ""}
                  disabled={disabledManagerDisplayFilter}
                  onClick={() => onClick(filter)}
                >
                  {t(filter.label)}
                </Button>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            className="contentGroupFilter"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Text>{t("Distribute by")}:</Text>
            <Select
              value={managerDisplaySelector}
              onChange={setManagerDisplaySelector}
              style={{ width: 150 }}
              disabled={managerDisplayFilter === "none"}
            >
              <Select.Option key={"nothing"} value={""}>
                {t("None")}
              </Select.Option>
              <Select.Option key={"country"} value={"country"}>
                {t("Countries")}
              </Select.Option>
              {isSuperUser && (
                <Select.Option key={"company"} value={"company"}>
                  {t("Media owners")}
                </Select.Option>
              )}
            </Select>
            {programmatic === "programmatic" && !isPaymentStatusApproved && (
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Tooltip
                  placement="left"
                  title={t(
                    "Equalize the spots across all screens, at the exact amount that keeps the campaign budget up to date."
                  )}
                >
                  <Button onClick={showModal}>
                    {t("Balance impressions")}
                  <Icon
                    className="cardAudienceIconInfo"
                    type="info-circle"
                    theme="outlined"
                  />
                  </Button>
                </Tooltip>
                <Modal
                  title={t('Confirm redistribution')}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={t('OK')}
                  cancelText={t('Cancel')}
                >
                  <p style={{margin: "20px",fontSize: "16px"}}>{t("You're about to equalize the spots across all screens, which will modify any previous spot distribution. Do you wish to continue?")}</p>
                </Modal>
              </div>
            )}
            <Tooltip placement="bottomLeft" title={t("Set up rules in campaign")}>
              <Badge count={calculateRules()} style={{ backgroundColor: "#3996f3" }}>
                <Button onClick={() => handleRulesChange(true)}>
                  {t("Create rules")}
                  <Icon type="control" />
                </Button>
              </Badge>
            </Tooltip>
            <Modal
              title={t("Create rules")}
              visible={rulesModalVisible}
              footer={[
                <Button key="ok" type="primary" onClick={() => handleRulesChange(false)}>
                  {t("Close")}
                </Button>,
              ]}
              onCancel={() => handleRulesChange(false)}
              width={800}
            >
              <RulesContainer
                typeModal="campaign"
                listContentDisplay={customContents}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </CampaignGroupFilterHolder>
  );
};

export default ScreensGroupFilterComponent;
