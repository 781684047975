export const TRANSLATIONS_EN = {
    "Welcome to React": "EN",
    "Language": "Language",

    //Language
    "es": "Español",
    "es_ar": "Español (Argentina)",
    "en": "English",
    "Search": "Search",
    "language iso 639-1": "EN",

    // Top bar component
    "I offered your screens": "Offer your screens",
    "My campaigns": "My campaigns",
    "Go to CMS" : "Go to CMS",
    "My profile": "My profile",
    "Sign off": "Sign off",
    "Sign up": "Sign up",
    "Sign up as an agency": "Sign up as an agency",
    "Sign up as an advertiser": "Sign up as an advertiser",
    "Log in": "Log in",
    "Login": "Login",
    "Screen manager": "CMS",
    "Agency Account": "Agency Account",
    "My quotes": "My proposals",
    "Password": "Password",
    "Password must be at least 6 characters": "Password must be at least 6 characters",

    "Code2FA": "Security code",
    "To log in, you must enable two-factor authentication (2FA) by accessing the CMS.": "To log in, you must enable two-factor authentication (2FA) by accessing the CMS.",
    "I forgot my password": "I forgot my password",
    "Dont have an account?": "Don't have an account?",
    "Do you already have your account?": "Do you already have your account?",
    "Are you a media owner?": "Are you a media owner?",
    "Register here": "Register here",
    "Create company account": "Create company account",
    "Recover your password": "Recover your password",
    "Enter your email and we will send you the instructions to reset your password.": "Enter your email and we will send you the instructions to reset your password.",
    "Send instructions": "Send instructions",
    "Resend verification email":"Resend verification email",
    "Enter your email and we will resend you the verification instructions.":"Enter your email and we will resend you the verification instructions.",
    "Resend email":"Resend email",
    "Company": "Company",
    "Name": "Name",
    "First Name": "First Name",
    "Last name": "Last name",
    "Phone": "Phone",
    "Data entered correctly, we will send you an email when we confirm your data.": "Data entered correctly, we will send you an email when we confirm your data.",
    "Session closed successfully": "Session closed successfully",
    "Welcome": "Welcome",
    "Error sending email": "Error sending email",
    "Selected screens must have the same currency": "Selected screens must have the same currency",
    "Good! An email was sent to your mailbox to retrieve your password.": "Good! An email was sent to your mailbox to retrieve your password.",
    "Good! An email was sent to your mailbox to activate yout account.": "Good! An email was sent to your mailbox to activate yout account.",
    "Good! Just one step left, activate your account.": "Good! Just one step left, activate your account.",
    "Good! You just need to verify your email": "Good! You just need to verify your email",
    "To begin you must verify your email. Check your email box to complete the registration process. Remember to check your spam folder.": "To begin you must verify your email. Check your email box to complete the registration process. Remember to check your spam folder.",
    "In order to use your account you have to activate it. Check your email address to finish the registration process." : "In order to use your account you have to activate it. Check your email address to finish the registration process. Remeber to check the spam folder.",
    "Url whatsapp, Hello, I would like to have advice publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20would%20like%20to%20have%20advice.",
    "Url whatsapp, Hello, I would like to have advice latinad": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20would%20like%20to%20have%20advice.",
    "Url whatsapp, Hello, I would like to have advice fenix": "https://api.whatsapp.com/send?phone=14073752728&text=Hi,%20I%20would%20like%20to%20have%20advice.",
    "Url whatsapp, Hello, I would like to have advice mediashake": "https://api.whatsapp.com/send?phone=573107964234&text=Hello,%20I%20want%20more%20information%20about%20MediaShake...",
    "Url whatsapp, Hello, I would like to have advice duoprint": "https://api.whatsapp.com/send?phone=593999560300&text=Hello,%20I%20want%20more%20information%20about%20DuoPrint...",
    "Url whatsapp, Hello, I would like to have advice hak media": "https://api.whatsapp.com/send?phone=56935574177&text=Hello,%20I%20want%20more%20information%20about%20%20Hakmedia...",
    "Currency": "Currency",
    "Terms": "Terms and Conditions",
    "Content Manager System": "Content Manager System",
    "Screen Player": "Screen Player",
    "Supply Side Platform": "Supply Side Platform",
    "Audience measurement": "Audience measurement",
    "Static OOH platform": "Static OOH platform",

    // Footer
    "You can start creating your campaign by entering the zone and date.": "You can star to create your campaign by entering zone and date.",
    "Advertisers": "Advertisers",
    "Create campaign": "Create campaign",
    "I am an agency": "I am an agency",
    "Purchase advice": "Purchase advice",
    "Screen owners": "Screen owners",
    "Offer your screens": "Offer your screens",
    "SSP": "SSP",
    "Product": "Product",
    "Certifications": "Certifications",
    "Community": "Community",
    "Success cases": "Success cases",
    "Plan your campaign": "Plan your campaign",
    "Technological solutions for the DOOH industry": "Technological solutions for the DOOH industry",

    "Contact": "Contact",
    "Write us by whatsapp": "Chat on WhatsApp",
    "Speak with us": "Speak with us",
    "Work with us": "Work with us",
    "Help": "Help",

    // TODO: not implemented
    "Hello, I would like to work with you. I leave information about me:": "Hola, me gustaría trabajar con ustedes. Les dejo información sobre mi:",

    "Url whatsapp, I am an agency publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20have%20an%20agency%20and%20I%20would%20like%20to%20be%20able%20to%20operate%20through%20the%20platform.",
    "Url whatsapp, I am an agency latinad": "https://api.whatsapp.com/send?phone=50254828708&text=Hello,%20I%20have%20an%20agency%20and%20I%20would%20like%20to%20be%20able%20to%20operate%20through%20the%20platform.",

    "Url whatsapp, Purchase advice publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20want%20to%20do%20a%20campaign%20I%20would%20like%20to%20have%20advice.",
    "Url whatsapp, Purchase advice latinad": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20want%20to%20do%20a%20campaign%20I%20would%20like%20to%20have%20advice.",

    "Mailto Work with us": "mailto:info@latinad.com?subject=I want to work with you&amp;body=Hello, I would like to work with you. I leave information about me:",

    "Our social networks": "Our social networks",
    "Find out about the latest news of the platform on our social networks.": "Find the latest updates on the platform on our social networks.",

    // SearchBoxComponent
    "Campaign location": "Campaign location",
    "Add Country or City?": "Add Country or City?",
    "Campaign start": "Campaign start",
    "End of campaign": "End of campaign",
    "Add dates": "Add dates",
    "Select a customer and brand to continue": "Select a customer and brand to continue",
    "Traditional": "Traditional",
    "Programmatic": "Programmatic",
    "Client": "Client",
    "Mark": "Brand",
    "": "",

    // HomeComponent
    "Digital signage": "Digital signage",
    "Digital signage (DOOH)": "Digital signage (DOOH)",
    "High-Value Audiences at a Click": "High-Value Audiences at a Click",
    "Boost your brand": "Boost your brand",
    "Create campaigns on public roads in minutes and reach thousands of people": "Create campaigns out of home in minutes and reach thousands of people",
    "Strategically positioned where your ideal audience is.": "Strategically positioned where your ideal audience is.",
    "Get to know us": "Get to know us",
    "Discover the latest in outdoor advertising": "Discover the latest in outdoor advertising",
    "Communicate your brand to thousands of people intelligently": "Communicate your brand to thousands of people intelligently",
    "Without intermediaries": "Without intermediaries",
    "Without calls": "Without calls",
    "100% Online": "100% Online",
    "Do you have screens?": "Do you have screens?",
    "Join the largest network in America, manage your business and increase sales.": "Join the largest network in America, manage your business and increase sales.",
    "Register as media company": "Register",
    "I dont have CMS": "I don't have CMS",
    "I have CMS": "I have CMS",
    "Know the media details": "Know the details",
    "Audience measurement with artificial intelligence": "Audience measurement with artificial intelligence",
    "Our campaigns are based on traffic and people metrics. Find out how we do it.": "Our campaigns are based on traffic and people metrics. Find out how we do it.",
    "Know the audience details": "Know the details",
    "Partners": "Partners",
    "Blog": "Blog and News",
    "Text Blog 1": "Create campaigns in LatinAd for +150 DSPs",
    "Description Blog 1": "Launch DOOH campaigns by purchasing all available media in Latin America and accessing more than 150 DSPs.",
    "Text Blog 2": "Plan in Latinad and implement in external DSPs",
    "Description Blog 2": "We share the tutorial with you.",
    "Text Blog 3": "Visit our help site",
    "Description Blog 3": "Help will teach you about Latinad.",
    "Platform integrated to the DOOH and programmatic Ecosystem": "Platform integrated to the DOOH and programmatic Ecosystem",
    "You must be logged in to see the price": "You must be logged in to see the price",

    // Info
    // "Watch our video": "Watch our video",
    // "You are part of the OOH advertising world on. Learn here.": "You are part of the OOH advertising world on. Learn here.",
    // "3 Simple Steps to start your campaign": "3 Simple Steps to start your campaign",
    // "It is everything you need to launch your campaign on public roads": "Our platform offers everything you need to launch your Out of Home campaign",
    // "Do you have screens?": "Do you have screens?",
    // "Join the largest Out of Home network in America": "Join the largest Out of Home network in America",

    // Expansion
    "Where we are?": "Where we are?",
    "Argentina": "Argentina",
    "Peru": "Peru",
    "Ecuador": "Ecuador",
    "Colombia": "Colombia",
    "Central America": "Central America",
    "Chile": "Chile",
    "Mexico": "Mexico",
    "US": "United States",

    // UserSettingComponent
    "Account": "Account",
    "Agency": "Agency",
    "Mail": "Mail",
    "Change Mail": "Change Mail",
    "New Mail": "New Mail",
    "Change Password": "Change Password",
    "Current Password": "Current password",
    "New Password": "New Password",
    "Repeat New Password": "Repeat New Password",
    "Repeat the old password": "Repeat old password",

    // QuotesComponent
    "Quotes": "Proposal",
    "Type": "Type",
    "Sub type": "Sub type",
    "Not assigned": "Not assigned",
    "See quote": "See proposal",
    "Delete quote": "Delete proposal",
    "Unarchived quote": "Unarchived proposal",
    "Archived quote": "Archived proposal",
    "Quote removed successfully": "Proposal removed successfully",
    "Quote archived successfully": "Proposal archived successfully",
    "Quote unarchived successfully": "Proposal unarchived successfully",
    "An error occurred while trying to delete quote": "An error occurred while trying to delete proposal",
    "An error occurred while trying to update quote": "An error occurred while trying to update quote",
    "Quote updated successfully": "Quote updated successfully",
    "Budget": "Budget",
    "Minumum budget": "El presupuesto debe ser mayor a ",
    "Created": "Created",
    "Start": "Start",
    "End": "End",
    "Qty. Screens": "Qty. Screens",
    "Action": "Action",

    // CampaignsComponent
    "Campaign successfully removed": "Campaign successfully removed",
    "You cannot delete campaigns with payments created": "You cannot delete campaigns with payments created",
    "No campaigns": "No campaigns",
    "There is no client": "There is no client",
    "There is no brand": "There is no brand",
    "An error occurred while deleting campaign": "An error occurred while deleting campaign",
    "Getting campaigns": "Getting campaigns",
    "You havent created your first campaign yet.": "You have not created your first campaign yet.",
    "Still to be paid": "Still to be paid",
    "Pending approval": "Pending approval",
    "Active": "Active",
    "Next": "Next",
    "Previous": "Finished",
    "Archived": "Archived",
    "Atention!": "Atention!",
    "You are about to delete a campaign. Are you agree?": "You are about to delete a campaign. Are you agree?",
    "You are about archived a campaign, then you can unarchived in seccion archived": "You are about archived a campaign, then you can unarchived in seccion archived",
    "You are about unarchive a campaign": "You are about unarchive a campaign",
    "OK": "Ok",
    "Cancel": "Cancel",
    "To modify this campaign, use the new version of the DSP with which it was created" : "To modify this campaign, use the new version of the DSP with which it was created.",
    "New version": "New version",
    "Version": "Version",
    "Are you sure you want to delete this relationship?": "Are you sure you want to delete this relationship?",
    "Are you sure you want to delete this relationship?. The content will be removed from the campaign because it will not belong to any relationship." : "The ad will be unlinked from the screen group of this resolution and will no longer be used in the campaign. You can add it back later.",
    "Manage ads and screens": "Manage ads and screens",
    "Ad library": "Ad library",
    "Campaign Ads": "Campaign Ads",
    "The ad will be detached from the group of screens of this resolution and will no longer be used in the campaign. You can add it again later.": "The ad will be detached from the group of screens of this resolution and will no longer be used in the campaign. You can add it again later.",
    "The ad will be unlinked from this screen. You can add it again later.": "The ad will be unlinked from this screen. You can add it again later.",
    "Are you sure you want to stop using this ad in your campaign? You can add it again later.": "Are you sure you want to stop using this ad in your campaign? You can add it again later.",
    "The ad will be disconnected from this screen. You can add it again later.": "The ad will be disconnected from this screen. You can add it again later.",

    // CampaignsCard
    "Ads rejected": "Ad(s) rejected(s)",
    "See detail": "See Detail",
    "Report": "Report",
    "View report": "See report",
    "Campaign created by": "Campaign created by",
    "the day": "The day",
    "at": "at",
    "for a total amount of": "For a total amount of",
    "Delete campaign": "Delete campaign",
    "Archive campaign": "Archive campaign",
    "Unarchive campaign": "Unarchive campaign",
    "Campaign created as Programmatic": "Campaign created as Programmatic",
    "Campaign created as Traditional": "Campaign created as Traditional",
    "Click for more information": "Click for more information",
    "Group screens": "Group screens",

    // CampaignsCardModal
    "See campaign":"See campaign",

    // Modal Replicate Campaign
    "Replicate campaign": "Replicate campaign",
    "Replicated": "Replicated",
    "Replicate campaign": "Replicate campaign",
    "The name is required": "The name is required",
    "A campaign with a pending payment status will be created, keeping the contents and screens that are still available for sale.": "A campaign with a pending payment status will be created, keeping the contents and screens that are still available for sale.",
    "The original campaign has rules based on dates, these will be removed. Remember to configure them again.": "The original campaign has rules based on dates, these will be removed. Remember to configure them again.",

    //Modal Edit Campaign Manager
    "Some ads do not match the screen resolutions": "Some ads do not match the screen resolutions",
    "in this group": "in this group",
    "on this display": "on this display",
    "The campaign allows a tolerance of": "The campaign allows a tolerance of",
    "%. If you want to increase it, you can adjust it from the previous step, in the Ads section.": "%. If you want to increase it, you can adjust it from the previous step, in the Ads section.",
    "This ad doesn't match any of the resolutions. Increase the campaign tolerance in the previous step and try again.": "This ad doesn't match any of the resolutions. Increase the campaign tolerance in the previous step and try again.",
    "Does not match any resolution, increase tolerance in the previous step.": "Does not match any resolution, increase tolerance in the previous step.",
    "Increase the campaign tolerance in the Ads section.": "Increase the campaign tolerance in the Ads section",
    "resolution.mismatch.single": "Some ads do not match the screen resolution. The campaign allows a tolerance of {{tolerance}}%. If you want to increase it, you can adjust it from the previous step, in the Ads section.",
    "resolution.mismatch.group": "Some ads do not match the screen resolutions in this group. The campaign allows a tolerance of {{tolerance}}%. If you want to increase it, you can adjust it from the previous step, in the Ads section.",

    // Modal Select Registration Type
    "Welcome to LatinAd": "Welcome to LatinAd",
    "Are you an agency?": "Are you an agency?",
    "Are you an advertiser?": "Are you an advertiser?",
    "Company name": "Company name",
    "Country": "Country",
    "State (optional)": "State (optional)",
    "User Email": "User Email",
    "First and last name": "First and last name",
    "Phone number": "Phone number",

    // CampaignsComponent
    "Screen resolution": "Screen resolution",
    "Announcement resolution": "Ad resolution",
    "Purchased impressions": "Purchased spots",
    "Promised impressions": "Promised spots:",

    "Screen details": "Screen details",
    "There was an error": "There was an error",
    "You do not have permissions to access this campaign": "You do not have permissions to access this campaign",
    "The requested campaign does not exist": "The requested campaign does not exist",
    "You must first delete the pending payment before retrying a new payment": "You must delete the pending payment before retrying a new payment",
    "Payment method": "Payment method",
    "Status": "Status",
    "Detail": "Detail",
    "Your Ads": "Your Ads",
    "Your payments": "Your payments",
    "Create new payment": "Create new payment",
    "Your ads for each screen": "Your ads for each screen",
    "See detailed report": "See detailed report",
    "The campaign will be paused, which means it will stop running and will not consume budget until it is reactivated.": "You are going to pause a campaign, so it will stop being displayed and will not consume budget until it is reactivated.",
    "The campaign will be activated, which means it will start running and begin consuming budget again until it is paused.": "The campaign will be activated, which means it will start running and begin consuming budget again until it is paused.",
    "Activate campaign": "Activate campaign",
    "Pause campaign": "Pause campaign",
    "Edit campaign": "Edit campaign",
    "View campaign in Campaign Manager": "View campaign in Campaign Manager",
    "The logged-in user is not the owner of this campaign": "The logged-in user is not the owner of this campaign, please switch companies to access the campaign",
    "Credit/debit card": "Credit/debit card",
    "MercadoPago": "MercadoPago",
    "Cash/Transfer": "Cash/Transfer",
    "Approved": "Approved",
    "Paused": "Pausado",
    "Ready": "Active",
    "Pending": "Pending",
    "Finalized": "Finalized",
    "Processing": "Processing",
    "Cancelled": "Cancelled",
    "Cancelled by user": "Cancelled by user",
    "Refunded": "Refunded",
    "In dispute": "In dispute",
    "Voided by claim": "Voided by claim",
    "Spots pending approval of the owner of each screen": "Spots pending approval of the owner for each screen",
    "Approved by the owners of each screen": "Approved by the owners of each screen",
    "Rejected": "Rejected",
    "To modify": "To modify",
    "Campaign pending approval status, check with your manager.": "Campaign is in approval pending status, check with your manager.",
    "Campaign in pending payment status.": "Campaign in pending payment status.",
    "Consider the following": "Consider the following",
    "When entering the edit mode of a campaign ": "When entering the edit mode of a campaign ",
    "some prices may be changed ": "some prices may be changed ",
    "(only in cases where screen owners have updated those prices), since this campaign does not have payments generated nor has it been launched, therefore that prices have not been frozen.": "(only in cases where screen owners have updated those prices), since this campaign does not have payments generated nor has it been launched, therefore that prices have not been frozen.",
    "Entering campaign edit mode. This campaign has approved payment, so only elements that do not alter its price can be edited.":"Entering campaign edit mode. This campaign has approved payment, so only elements that do not alter its price can be edited.",
    "Go to edit": "Go to edit",
    "Cancel": "Cancel",
    "Proposal": "Proposal",
    "Generate proposal": "Generate proposal",
    "Campaign details": "Campaign details",
    "Category": "Category",

    // CampaignTopbar
    "Campaign status": "Campaign status",
    "Exhibition date": "Exhibition date",
    "Duration": "Duration",
    "Real Impressions": "Real spots",
    "Show map": "Show map",
    "Starts": "Beginning",
    "Today": "Today",
    "Finished": "Finished",
    "Campaign country": "Country",

    // PaymentStatusComponent
    "Cash": "Cash",
    "Check": "Bank Check",
    "Transfer": "Transfer",
    "Bank data": "Bank data",
    "Current account": "Credit account",
    "Direct credit": "Direct Credit",
    "Payment made through Mercado Pago with Card ending in": "Payment made through Mercado Pago with Card ending in",
    "Payment made by Mercado Pago":  "Payment made by Mercado Pago",
    "Card": "Card",
    "finished in": "finished in",
    "Unknown payment method": "Unknown payment method",
    "Final consumer":  "Final consumer",
    "Invoice": "Invoice",
    "Your payment has already been approved.": "Your payment has already been approved.",
    "The payment is pending accreditation.": "The payment is pending accreditation.",
    "The payment is pending payment.": "Payment is pending.",
    "Your payment has been credited successfully.": "Your payment has been credited successfully.",
    "The payment was canceled, you can generate another payment and choose the method you want.": "The payment was canceled, you can generate another payment and choose the method you want.",
    "We are processing your payment. In less than two business days we will notify you of the result.": "We are processing your payment. In less than two business days we will notify you of the result.",
    "Check the card number.": "Check the card number.",
    "Please review the data and try again.": "Please review the data and try again.",
    "Check the security code.": "Check the security code.",
    "We were unable to process your payment. Please try another payment method.": "We were unable to process your payment. Please try another payment method.",
    "Please call your card to authorize the payment and try again.": "Please call your card to authorize the payment and try again.",
    "Your card is not active. Please call the phone number on the back of your card.": "Your card is not active. Please call the phone number on the back of your card.",
    "You already made a payment for that value. If you need to pay again, use another card or another means of payment.": "You already made a payment for that value. If you need to pay again, use another card or another payment method.",
    "Your payment was declined. Choose another of the means of payment, we recommend using cash.": "Your payment was declined. Choose another payment method, we recommend using cash.",
    "Your card does not have sufficient funds to carry out the operation.": "Tu tarjeta no tiene fondos suficientes para realizar la operación.",
    "Your card cannot process installment payments.": "Your card cannot process installment payments.",
    "You have reached the limit of allowed attempts. Choose another card or other means of payment.": "You have reached the limit of allowed attempts. Choose another card or another payment method.",
    "Start date": "Starting date",
    "Finish date": "Final date",
    "Number of screens": "Number of screens",
    "Total campaign price": "Total campaign price",
    "Successful payment": "Successful payment",
    "Pending payment": "Pending payment",
    "Payment canceled": "Payment canceled",
    "Payment declined": "Payment declined",
    "Campaign": "Campaign",
    "Payment": "Payment",
    "Payment method": "Payment method",
    "Total without tax": "Total without tax",
    "Total with tax": "Total with tax",
    "tax included": "tax included",
    "more taxation":  "+ tax",
    "+tax": "+tax",
    "Billing information": "Billing information",
    "If you want to pay with another means of payment, you can return to the list of payments in your campaign, delete the pending payment, and make a new payment.": "If you want to pay with another payment method, you can return to the list of payments in your campaign, delete the pending payment, and make a new payment.",
    "Go to my campaign": "Go to my campaign",
    "Summary": "Summary",
    "See spot": "See spot",
    "Discount": "Discount",
    "Your ads have been sent to the owners of each screen for their review and approval. This process can take up to 48 hours. We will send you a notification by mail when this process is complete.": "Your ads have been sent to the owners of each screen for their review and approval. This process can take up to 48 hours. We will send you a notification by mail when this process is complete.",
    "pending": "Pending",
    "approved": "Approved",
    "cancelled": "Cancelled",
    "rejected": "Rechazado",
    "View purchase order":"View purchase order",
    "Upload ODC": "Upload PO",
    "Date expiry": "Date expiry",
    "Document type": "Document type",
    "Document number": "Document number",
    "Name as it appears on the card": "Name as it appears on the card",
    "Campaign name": "Campaign name",

    // AdScreenCard
    "This would modify the value of the campaign, create a new campaign or contact support": "This would modify the value of the campaign, create a new campaign or contact support",
    "The file cannot be larger than 50MB!": "The file cannot be larger than 50MB!",
    "successfully uploaded": "successfully uploaded",
    "You cannot upload files longer than those allowed by the programmatic": "You cannot upload files longer than those allowed by the programmatic",
    "You cannot upload files longer than what is allowed on the screen": "You cannot upload files longer than what is allowed on the screen",
    "Upload error": "Upload error",
    "Spot Approved": "Approved",
    "Spot Rejected": "Rejected",
    "Real time impressions": "Real time spots",
    "The owner of this screen has not yet approved": "The owner of this screen has not yet approved",
    "View screen details": "View screen details",
    "Screen Format": "Screen Format",
    "Ad format": "Ad format",
    "Price": "Price range",
    "Subsidized": "Subsidized",
    "Pre approved": "Pre approved",
    "Coming soon": "Coming soon",
    "Total subsidize": "Total subsidize",
    "subsidized": "subsidized",
    "Suspended": "Suspended",
    "It will be in suspended state if the value is 0.": "It will be in suspended state if the value is 0.",
    "Campaign subsidized": "Campaign subsidized",
    "Subsidize impression": "spots subsidize",
    "These impressions are reflected as subsidize in the campaign": "These spots are reflected as subsidize in the campaign",
    "Preview": "Preview",
    "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration.": "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration.",
    "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration. No immediately": "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration. This action will be effective in up to 2 hours after approval",
    "Change ad": "Change ad",
    "This action will change the ad (new piece that will go up). It does require a new approval in the event that the previous piece has already been approved. Remember to upload a similar piece, from the same campaign, client and brand.": "This action will change the ad (new piece that will go up). It does require a new approval in the event that the previous piece has already been approved. Remember to upload a similar piece, from the same campaign, client and brand.",
    "Camera to traffic": "Camera to traffic",
    "View live traffic": "View live traffic",
    "Traffic camera for audience control and measurement": "Traffic camera for audience control and measurement",
    "You have not configured a camera pointing at traffic": "You have not configured a camera pointing at traffic",
    "Camera for certification": "Camera for certification",
    "No data": "No data",
    "Traffic today": "Traffic today",
    "Real-time vehicular traffic count": "Real-time vehicular traffic count",
    "Traffic this week vs previous week": "Traffic this week vs previous week",
    "Weekly count by type": "Weekly count by type",
    "Movement per hour in the week": "Movement per hour in the week",
    "View live screen": "View live screen",
    "Camera looking at screen for certification": "Camera looking at screen for certification",
    "You have not configured camera pointing at the screen": "You have not configured camera pointing at the screen",
    "See evidence": "See evidence",
    "Evidence": "Evidence",
    "M": "M",
    "T": "T",
    "W": "W",
    "TH": "T",
    "F": "F",
    "SA": "S",
    "SU": "S",
    "Every day": "Every day",
    "All day": "All day",
    "Rule": "Rule",

    // CartComponent
    "Quote created successfully": "Proposal created successfully",
    "An error occurred while trying to create a quote": "An error occurred while trying to create a proposal",
    "See cart": "See cart",
    "to cart": "to cart",
    "Estimated campaign budget": "Estimated campaign budget",
    "In the next step you will be able to configure the impressions per screen and you will see how the budget varies according to the impact you want.": "In the next step you will be able to configure the spots per screen and you will see how the budget varies according to the impact you want.",
    "The minimum purchase price is not reached": "The minimum purchase price is not reached",
    "In the next step you will be able to configure the impressions per screen and you will see how the budget varies according to the impact you want.": "In the next step you will be able to configure the spots per screen and you will see how the budget varies according to the impact you want.",
    "You must select a customer and brand before starting a campaign launch": "You must select a customer and brand before starting a campaign launch",
    "You must select a customer and brand before creating a quote": "You must select a customer and brand before creating a proposal",
    "CREATE PROPOSAL": "CREATE PROPOSAL",
    "EXPORT EXCEL": "Export Excel",
    "EXPORT MAP": "Export Map",
    "There are no more screens in the cart": "There are no more screens in the cart",
    "Empty cart": "Empty cart",
    "Create Quote": "Create Proposal",
    "Ads cannot be edited in approved campaigns": "Ads cannot be edited in approved campaigns",
    "Editing the campaign": "Editing campaign",
    "Exit edit": "Exit edit",
    "Edit": "Edit",
    "If you exit out of edit mode, the cart and contents empty": "If you exit out of edit mode, the cart and contents empty.",
    "Slot duration": "Slot duration",
    "The minimum purchase price is not reached": "The minimum purchase price is not reached",
    "The minimum purchase price is": "The minimum purchase price is",
    "Currency exchange rate": "Currency exchange rate",
    "See currency exchange rate": "See exchange rate",
    "Conversion generated at 11AM (Argentina time) today": "Conversion generated at 11AM (Argentina time) today",

    // CheckoutDetails
    "Total cost": "Total",
    "Subtotal cost": "Subtotal",
    "Suggested price in local currency": "Suggested price in local currency",
    "It is the price that you should use when implementing the campaign in the chosen DSP, only if you want to do it in local currency.": "It is the price that you should use when implementing the campaign in the chosen DSP, only if you want to do it in local currency.",
    "Tax details": "Tax included",
    "Finish": "Finish",
    "Finish process for subsequent approval": "Finish process for subsequent approval",
    "Make payment": "Make payment",
    "View campaign": "View campaign",
    "Go Mercado Pago": "Go to Mercado Pago",
    "Apply discount coupon": "Apply discount coupon",
    "Coupon code": "Coupon code",
    "Remove discount": "Remove discount",

    // FiltersComponent
    "Client and brand": "Client / Brand",
    "Change campaign dates": "Change campaign dates",
    "Price per day per screen": "Price per day per screen",
    "Impressions per day per screen": "Spots per day per screen",
    "Impressions": "Spots",
    "All": "All",
    "Outdoor": "Outdoor",
    "Indoor": "Indoor",
    "Mobile": "Mobile",
    "Point of sale": "Point of sale",
    "Size": "Size",
    "Small": "Small",
    "Medium": "Medium",
    "Big": "Big",
    "Giant": "Giant",
    "Other Filters": "Other Filters",
    "Circuit": "Show circuits only",
    "Show and edit filters": "Show and edit filters",
    "circuit": "Circuit",

    // ScreenCardComponent
    "Real-time audience": "Real-time audience",
    "Audience detail": "Audience detail",
    "Non-automated screen. Click to see detail": "Non-automated screen. Click to see detail",
    "Non-automated screen": "Non-automated screen",
    "This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.": "This screen does not have the real-time print notice. So the spots reports can take up to be reflected, and they can be out of date in time.",
    "Screen belonging to the circuit": "Screen belonging to the circuit",
    "of count_secondary_displays screens": "of count_secondary_displays screens.",
    "It is not possible to purchase these screens individually." : "It is not possible to purchase these screens individually.",
    "Circuit": "Circuit",
    "spots/day": "spots / day",
    "Audience Average": "Audience Average",
    "Average audience of your screen": "Average audience of your screen(s)",
    "Audience by day of the week": "Average audience by day of the week",
    "Audience by time of day": "Average audience by hour of the day",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Average audience of your screen by day of the week": "Average audience of your screen(s) by day of the week",
    "Average audience per hour in the day": "Average audience per hour in the day",
    "Select days of the week": "Select days of the week",
    "Filter audience by day of the week": "Filter audience by day of the week",
    "Filter": "Filter",
    "Average audience by date": "Average audience by date",
    "Select hours of the day": "Select hours of the day",
    "Filter audience by day of the week or hour": "Filter audience by day of the week or hour",
    "Total number of people impacted": "Total number of people impacted",
    "Day of greatest impact": "Day of greatest impact",
    "Biggest Impact/Day": "Biggest Impact/Day",
    "Hour with the greatest impact": "Hour with the greatest impact",
    "Biggest Impact/Hour": "Biggest Impact/Hour",
    "Sum of Impacts per day (Dates)": "Sum of Impacts per day (Dates)",
    "Average Impacts per Hour (Dates)": "Average Impacts per Hour (Dates)",
    "Comparison by Screen": "Comparison by Screen",
    "Sum of Impacts per day": "Sum of Impacts per day",

    // RequestSpecialCpmComponent - RequestSpecialPriceComponent
    "Agreed prices": "Agreed prices",
    "Send request": "Send request",
    "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and LatinAd will notify you when the set price is reached to continue with your purchase.": "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and LatinAd will notify you when the set price is reached to continue with your purchase.",
    "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and the system will notify you when the set price is reached to continue with your purchase.": "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and the system will notify you when the set price is reached to continue with your purchase.",
    "The original price is" : "The original price is",
    "per thousand spots.": "per thousand spots.",
    "per day.": "per day.",
    "Agreed price": "Agreed price",
    "Final prices may contain platform commissions and may vary depending on the selected client": "Final prices may contain platform commissions and may vary depending on the selected client",
    "Request agreed price": "Request agreed price",
    "The agreed price must be greater than 0": "The agreed price must be greater than 0",
    "The agreed price must be less than the original price": "The agreed price must be less than the original price",
    "There is already a cpm price arrangement request for this display": "There is already a cpm price arrangement request for this display",
    "There is already a cpm price arrangement for this display": "There is already a cpm price arrangement for this display",
    "There is already a price arrangement request for this display": "There is already a price arrangement request for this display",
    "There is already a price arrangement for this display": "There is already a price arrangement for this display",
    "Request sent successfully": "Request sent successfully",
    "To keep in mind": "To keep in mind",
    "The agreed-upon price does not include local or international taxes.":"The agreed-upon price does not include local or international taxes.",
    "Final prices may include platform fees and may vary depending on the selected client.": "Final prices may include platform fees and may vary depending on the selected client.",
    "In the event that the screen price is in a different currency than the one being used for the purchase, final prices may vary due to conversion.": "In the event that the screen price is in a different currency than the one being used for the purchase, final prices may vary due to conversion.",
    "You already have an agreed price": "You already have an agreed price",

    // CardComponent
    "screens that you added to the cart have cameras measuring audience. You can go to the detail of each one to analyze the data and optimize your campaign": "screens that you added to the cart have measuring audience. You can go to the detail of each one to analyze the data and optimize your campaign",
    "Screens measuring audience": "Screens measuring audience",
    "Total screens added in the campaign": "Total screens added in the campaign",
    "Estimated impressions based on campaign settings. You can add campaign days (in traditional) or increase the budget (in programmatic).": "Number of people who will see your ad or group of ads based on campaing settings.",
    "Estimated audience": "Estimated audience",
    "See audience details": "See estimate audience details",
    "See audience button": "See",
    "Audience impacts": "Estimate reach",
    "Value based on screen occupancy statistics, so it can vary daily.": "Number of people who will see your ad or group of ads based on campaing settings.",
    "(CPM) cost per thousand impression": "(CPM) cost per thousand impression",
    "CPM Average": "CPM Average",
    "impacts": "Impressions",
    "Upload material":"Upload material",
    "Versions": "Versions",
    "Selected ads": "Selected ads",
    "Used ads": "Used ads",

    // CheckoutStepsComponent
    "Campaign budget": "Campaign budget",
    "You can configure the impressions per screen and see how the updated budget varies according to the impact you want.": "You can configure the spots per screen and see how the updated budget varies according to the impact you want.",

    // StepsComponent
    "Select screens": "Select screens",
    "Upload your spots": "Upload your spots",
    "Launch your campaign": "Launch your campaign",

    //Contents of campaign
    "Required resolutions": "Required resolutions",
    "Required resolution": "Required resolution",
    "Tolerance": "Tolerance",
    "Clear progress": "Clear progress",
    "Clear all contents added to the campaign, clearing its progress": "Clear all contents added to the campaign, clearing its progress",
    "Info tolerance": "By increasing the tolerance, it will allow ads to be displayed on screens that do not match the exact resolution (you can then choose to show black spaces or stretch the ad).",
    "for the screen": "For the screen",
    "for these versions": "for these versions",
    "for screens grouped as": "For screens grouped as",
    "To replace this content": "To replace this spot",

    // VideoUploadComponent
    "the file is not ready yet": "the file is not ready yet",
    "upload a file for the campaign": "upload a file for the campaign",
    "Campaign successfully created": "Campaign successfully created",
    "You can see it in the My campaigns section.": "You can see it in the My Campaigns Section",
    "You have not selected screens, you can go back to the map and do it from there.": "You have not selected screens, you can go back to the map and do it from there.",
    "Your campaign": "Your campaign",
    "Contents library": "Ads library",
    "Content added in campaign list": "Content added in campaign list",
    "You must select a content": "Select ads in the section above",
    "Screens": "Screens",
    "Aspect ratio": "Aspect ratio",
    "General dimensions":  "General dimensions",
    "Resolution": "Resolution",
    "Type of screen": "Type of screen",
    "Ubication": "Ubication",
    "Group by":"Agrupar por",
    "Contents for the campaign": "Group screens by",
    "Give your campaign a name so you can identify it": "Give your campaign a name so you can identify it",
    "Add more information if you need it" : "Add more information if you need it",
    "Campaign objective": "Campaign objective",
    "You reached the character limit!" : "You reached the character limit!",
    "Campaign name": "Campaign name",
    "Campaign extra information": "Campaign extra information",
    "Briefly describe the purpose and goals of your campaign.": "Briefly describe the purpose and goals of your campaign.",
    "Is it a test campaign?": "Is it a test campaign?",
    "Pause campaign": "Pause campaign",
    "Pause content": "Pause ad",
    "Pause display": "Pause display",
    "You have already uploaded your ad! If you want to change it for all screens, click below": "You have already uploaded your ad! If you want to change it for all screens, click below",
    "Change spot material": "Change ad material",
    "general rule": "general rule",
    "General Rule (will apply to all screens)": "General Rule (will apply to all screens)",
    "Delete ad": "Delete ad",
    "Clear campaign progress in progress": "Clear campaign progress in progress",
    "Yes, clean": "Yes, clean",
    "All the progress you have made so far in this campaign will be cleaned up, such as the campaign name, the ads for each screen, and the settings.": "All the progress you have made so far in this campaign will be cleaned up, such as the campaign name, the ads for each screen, and the settings.",
    "Upload your ad": "Upload your ad",
    "Upload your ad (an image or a video), to preview how it will look on each of the screens you chose.": "Upload your ad (an image or a video), to preview how it will look on each screen you have chosen.",
    "Drag the ad here or": "Drag the ad here or",
    "click to load it.": "click to load it.",
    "Dont have the ad?": "Don't have the ad?",
    "Contact us and we will advise you.": "Contact us and we will advise you.",
    "Url whatsapp, Contact us": "https://api.whatsapp.com/send?phone=5492615019508&text=Hello,%20I%20would%20like%20to%20advertise%20but%20I%20still%20do%20not%20have%20the%20graphic%20piece.",
    "The time indicated by each screen will be displayed.": "The time indicated by each screen will be displayed.",
    "You can change the ad for each of the selected screens. Keep in mind that if it is an image, the ad will display the space time indicated by each screen. For videos, it will be the time of the video.": "You can change the ad for each of the selected screens. Keep in mind that if it is an image, the ad will display the space time indicated by each screen. For videos, it will be the time of the video.",
    "Selected screens": "Selected screens",
    "Updated budget": "Updated budget",
    "Tight budget": "Tight budget",
    "Balance $": "Balance $",
    "Redistribute": "Redistribute",
    "Total spots": "Total spots",
    "You can configure the spots per screen and see how the adjusted budget varies depending on the impact you want.": "You can configure the spots per screen and see how the adjusted budget varies depending on the impact you want.",
    "Upload your main piece to see how it will look on each screen": "Upload your main piece to see how it will look on each screen",
    "Your video of": "Your video of",
    "secs exceeds the playing time accepted by some selected screens. You can change the ad for a shorter one, or you can leave it and pay the proportional value for the time exceeded.": "secs exceeds the playing time accepted by some selected screens. You can change the ad for a shorter one, or you can leave it and pay the proportional value for the seconds exceeded.",
    "secs exceeds the standard time for programmatic campaigns of 10 seconds. You can change the ad for a shorter time.": "secs exceeds the standard time for programmatic campaigns of 10 seconds. You can change the ad for a shorter time.",
    "Your ad is": "Your ad is",
    "an image": "an image",
    "a video": "a video",
    "Printed so far": "Printed so far",
    "It has a duration of": "It has a duration of",
    "secs, and this time will be displayed": "secs, and this time will be displayed",
    "Campaign successfully edited": "Campaign successfully edited",
    "You can review it from this section before generating a payment.": "You can review it from this section before generating a payment.",
    "Campaign": "Campaign",
    "You can exit edit mode to create a new campaign.": "You can exit edit mode to create a new campaign.",
    "You are in campaign edit mode": "You are in campaign edit mode",
    "Exit": "Exit",
    "Go back to the original budget and recalculate impressions (changes you have made in impressions for each Spot will be erased)": "Go back to the original budget and recalculate spots (changes you have made in spots for each Spot will be erased)",
    "Go back to the original budget (n_amountBudget) and recalculate impressions (changes you have made in impressions for each Spot will be erased)": "Go back to the original budget (n_amountBudget) and recalculate impressions (changes you have made in impressions for each Spot will be erased)",
    "Redistribute the new budget and recalculate spots (changes made to spots for each ad will be deleted)": "Redistribute the new budget and recalculate spots (changes made to spots for each ad will be deleted)",
    "For each screen it will equalize the investment and recalculate the spots (changes you have made to spots for each ad or screen will be erased)": "For each screen it will equalize the investment and recalculate the spots (changes you have made to spots for each ad or screen will be erased)",
    "The initial amount was modified; you can redistribute the budget": "The initial amount was modified; you can redistribute the budget",
    "Increase prints to fill all remaining": "Increase prints to fill all remaining",
    "Design and change spot": "Design and change ad",
    "Design spot": "Design ad",
    "What resolution will your main Spot have?": "What resolution will your main Spot have?",
    "Width (px)": "Width (px)",
    "Height (px)": "Height (px)",
    "You will need to create an account and log in the first time you want to create a design.": "You will need to create an account and log in the first time you want to create a design.",
    "Identify your ad...": "Identify your ad...",
    "None": "None",
    "Contents": "Contents",
    "Configuration": "Configuration",
    "Make payment": "Make payment",
    "View contents from the folder": "Announcement folder",
    "Upload": "Select",
    "Upload continue": "or drag",
    "Upload continue 2": "ads here",
    "Upload message 1": "Select a",
    "Upload message 2": "folder to",
    "Upload message 3": "upload ads",
    "Folder advertisement": "A folder is a container of ads grouped by brands. To create new folders you must create new brands within clients in the CMS.",
    "Version explain": "Before selecting ads for your campaign, you can categorize them by the appropriate version. For example: Version 1 = Breakfast, Version 2 = Mid-afternoon.",
    "Select a person": "Select a person",
    "None": "None",
    "There are ads that do not adapt correctly to the resolutions. You can continue or select ads from closer resolutions." : "There are ads that do not adapt correctly to the resolutions. You can continue or select ads from closer resolutions.",

    //ContentsLibraryComponent
    "Library of contents": "Library of contents",
    "Choose the version before choosing content": "Choose the version before choosing content",
    "Remove ad from the library": "Remove ad from the library",
    "Add content to the campaign": "Add content to the campaign",
    "This ad does not fit the screen resolution according to the tolerance set in the campaign": "This ad does not fit the screen resolution according to the tolerance set in the campaign",
    "Remove ads from the campaign": "Remove ads from the campaign",
    "Stop using this ad for the campaign.": "Stop using this ad for the campaign.",
    "Copy the name of this ad to all ads in the same version.": "Copy the name of this ad to all ads in the same version.",
    "You are about to clone this ad name to all other ads in the same version. This action will overwrite the current names.": "You are about to clone this ad name to all other ads in the same version. This action will overwrite the current names.",
    "Apply ad to available resolutions": "Apply ad to available resolutions",
    "Remove relation between content and displays": "Remove relation between content and displays",
    "Unlink ad from this screen group.": "Unlink ad from this screen group.",
    "It's the last relation on a screen, to delete it, its contents should be reorganized": "It's the last relation on a screen, to delete it, its contents should be reorganized",
    "Are you sure you want to remove this content from the campaign": "Are you sure you want to remove this content from the campaign?",
    "version": "Version",
    "Version of the ad": "Version of the ad",
    "Remplace version": "Remplace version",
    "Remplace": "Remplace",
    "Create another version": "Create another version",
    "A version with that resolution already exists, you want to replace the content": "A version with that resolution already exists, you want to replace the content",
    "A version with that resolution already exists on another screen, a new version will be created to avoid replacing the one currently in use.": "A version with that resolution already exists on another screen, a new version will be created to avoid replacing the one currently in use.",
    "Select a folde": "Select a folder",
    "This folder is not modified": "This folder is not modified",
    "Content information": "Content information",
    "Displays added in this relation ": "Displays added in this relation",
    "Hours": "Hours",

    // ModalContent
    "Content data": "Content data",
    "Are you sure you want to delete this spot?": "Are you sure you want to delete this spot? You can add it again later.",
    "Are you sure you want to delete all progress so far? It will remove the loaded ads and created relationships.": "Are you sure you want to delete all progress so far? It will remove the loaded ads and created relationships.",
    "Delete": "Delete",

    //CardGeneralComponent
    "General": "General",
    "Number of screens": "Number of screens",
    "Total price": "Total price",

    // ScreenPreview
    "Add an Spot for this screen. (In case they meet the same rules, it will print once each)": "Add an Spot for this screen. (In case they have the same rules, it will print once each)",
    "In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time": "In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time",
    "Exceeded in": "Exceeded in",
    "secs": "secs",
    "If you do not change the ad for a shorter one, it will add approximately": "If you do not change the ad for a shorter one, it will add approximately",
    "per day for this screen.": "per day for this screen.",
    "Expand ad": "Adjust material size to the resolution of the screen",
    "Stretch ad/s on group screens. This can distort the ads.": "Stretch ad/s on group screens. This can distort the ads.",
    "Do not stretch ad/s on the screens. Black spaces can be seen.": "Do not stretch ad/s on the screens. Black spaces can be seen.",
    "Stretch ad/s until they occupy 100% of the screen. This may distort the advertisement.": "Stretch ad/s until they occupy 100% of the screen. This may distort the advertisement.",
    "Do not stretch ad/s on the screen. Black spaces can be seen.": "Do not stretch ad/s on the screen. Black spaces can be seen.",
    "Stretch the ad until it fills 100% of the screen. This may distort the advertisement.": "Stretch the ad until it fills 100% of the screen. This may distort the advertisement.",
    "Do not stretch the ad on the screen. Black spaces can be seen.": "Do not stretch the ad on the screen. Black spaces can be seen.",
    "Time of each space on this screen. An extra fee may be charged if the advertisement exceeds this time.": "Time of each space on this screen. An extra fee may be charged if the advertisement exceeds this time.",
    "By enabling this option, the ad is subsidized, affecting the price of the campaign.": "By enabling this option, the ad is subsidized, affecting the price of the campaign.",
    "Return original resolution": "Return original resolution",
    "Ad created in canva, you can edit and change it for this screen or relationship.": "Ad created in canva, you can edit and change it for this screen or relationship.",
    "Create new ad in canva for this relationship": "Create new ad in canva for this relationship",
    "Change material": "Change material",
    "Change ad for this": "Change ad for this",
    "Change ad for this screens": "Change ad for this screens",
    "relationship": "relationship",
    "Remove spot": "Remove spot",
    "Screen detail": "Screen detail",
    "Remove screen": "Remove screen",
    "Remove group of screens": "Remove group of screens",
    "Remove screen from campaign": "Remove screen from campaign",
    "Add another rule": "Add another rule",
    "Add rule": "Add rule",
    "Configuration rules on": "Configuration rules on",
    "Remove rule": "Remove rule",
    "Remove rules": "Remove rules",
    "Clone rules": "Clone rules",
    "Standard time of the programmatic space, an extra could be charged in the case of a longer ad.": "Standard time of the programmatic space, an extra could be charged in the case of a longer ad.",
    "Screen space time": "Screen space time",
    "Space time": "Space length",
    "Standard time of the space of this screen, an extra could be charged in the case of being a longer ad.": "Standard time of the space of this screen, an extra could be charged in the case of being a longer ad.",
    "Potential reach in real time of the screen based on the measurement data with cameras, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.": "Potential reach in real time of the screen based on the audience data, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.",
    "Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.":"Real-time screen impression, shows the number of people who will see your ad or group of ads based on audience data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impression it will generate.",
    "Audience data provided by the media. They are impression based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.": "Audience data provided by the media. Based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.",
    "See screen detail to see the days and hours of operation, to optimize your rules": "See screen detail to see the days and hours of operation, to optimize your rules",
    "Subsidize price of this screen": "Subsidize price of this screen",
    "Subsidize price of campaign": "Subsidize price of campaign",
    "First load content for subsidize the campaign": "First load content for subsidize the campaign",
    "By enabling this option, the screen is subsidize, affecting the price of the campaign": "By enabling this option, the screen is rewarded, affecting the price of the campaign",
    "By enabling this option, the spot is subsidize, affecting the price of the campaign": "By enabling this option, the spot is subsidize, affecting the price of the campaign",
    "By enabling this option, the campaign is subsidize, your price will be zero": "By enabling this option, the campaign is subsidize, your price will be zero",
    "Avoid getting your ad disapproved, add %deference_cpm impressions to cover the CPM!": "Avoid getting your ad disapproved, add %deference_cpm spots to cover the CPM!",
    "Congratulations! You reached the minimum CPM": "Congratulations! You reached the minimum CPM",
    "Congratulations! You exceeded the minimum CPM": "Congratulations! You exceeded the minimum CPM",
    "To delete need at least two screens in the campaign" : "To delete need at least two screens in the campaign",
    "Setting this value in all screen and spot": "Setting this value in all screen and spot",
    "Add contents on the screen": "Add ads on the screen",
    "Add contents to screen group": "Add ads to screen group",
    "Set up rules in group of displays": "Set up rules in group of displays",
    "Set up rules in campaign": "Set up rules in campaign",
    "Clone spot": "Clonar spot",

    // PaymentComponent
    "Error applying coupon": "Error applying coupon",
    "The coupon has already been applied": "The coupon has already been applied",
    "Coupon does not exist": "Coupon does not exist",
    "A coupon has already been applied in this campaign": "A coupon has already been applied in this campaign",
    "An error occurred while applying coupon": "An error occurred while applying coupon",
    "Coupon applied successfully": "Coupon applied successfully",
    "Failed to delete coupon": "Failed to delete coupon",
    "Coupon removed successfully": "Coupon removed successfully",
    "The campaign has already been paid.": "The campaign has already been paid.",
    "Congratulations! Your payment has been credited.": "Congratulations! Your payment has been credited.",
    "You must select a country": "You must select a country",
    "The month / year": "The month / year",
    "Its not valid":"It's not valid",
    "Campaign payment": "Campaign payment",
    "NAME LAST NAME": "NAME LAST NAME",
    "Pay by credit / debit card": "Pay by credit / debit card",
    "Pay with other means of payment": "Pay with other payment method",
    "Pay with Mercado Pago": "Pay with Mercado Pago",
    "Information for direct cash payments with": "Information for direct cash payments with",
    "Please complete the form with your billing information and your contact telephone number, a n_label collector will contact you to coordinate the collection.": "Please complete the form with your billing information and your contact telephone number, a n_label collector will contact you to coordinate the collection.",
    "Telephone contact": "Telephone contact",
    "Bank data": "Bank data",
    "Card number": "Card number",
    "Please make a transfer to the following account, and put the number as a reference": "Please make a transfer to the following account, and put the number as a reference",
    "Information for direct current account payments with": "Information for direct current account payments with",
    "Once the payment has been made using this method, your media manager will be able to approve the campaign.": "Once the payment has been made using this method, your media manager will be able to approve the campaign.",
    "Billing Country": "Billing Country",
    "Document type": "Document type",
    "Document number": "Document number",
    "export": "export",
    "Fill in the following information": "Fill in the following information",
    "Address": "Address",
    "Street": "Street",
    "Social name": "Name / Social name",
    "Number": "Number",
    "Province": "State",
    "Location": "Location",
    "Billing to client": "Client billing",
    "Billing to agency": "Agency billing",
    "Last billing": "Last billing",
    "Additional Information": "Additional Information",
    "Security code": "Security code",
    "Billing": "Billing",
    "Postal code": "Postal code",
    "Generate ODC": "Generate Purchase order",
    "Creating ODC": "Creating PO",
    "Created ODC": "Created PO",
    "Ups, problem for create ODC": "Ups, problem for create PO",
    "Try our new version": "Try our new version",

    // ODC modal
    "Purchase orders": "Purchase orders",
    "Date creation": "Date creation",
    "Files": "Files",
    "There are no purchase orders uploaded": "There are no purchase orders uploaded",
    "view": "View",
    "ODC": "PO",

    // PaymentTopbar
    "Go to map": "Go to map",

    // ScreenComponent
    "South": "South",
    "North": "North",
    "West": "West",
    "East": "East",
    "Northeast": "Northeast",
    "Northwest": "Northwest",
    "Southeastern": "Southeastern",
    "Southwest": "Southwest",
    "Class": "Class",
    "Middle": "Middle",
    "High": "High",
    "Lower": "Lower",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Sun": "Sun",
    "Off": "Off",
    "Remove from cart": "Remove",
    "Add to cart": "Add",
    "Go to buy": "Go to buy",
    "Description": "Description",
    "Information": "Information",
    "Resolution": "Resolution",
    "Ads time": "Ads time",
    "Length of ads time": "Length of ads time",
    "It is an approximate value, because is upload by screen owner.": "It is an approximate value, because is upload by screen owner.",
    "There is no audience measurement configured": "There is no audience measurement configured",
    "Ad Duration": "Ad Duration",
    "Approximate impressions": "Approximate spots",
    "Approx impr": "Approx. impr.",
    "It is an approximate value, because it depends on the days and rules you choose in your campaign. Once the campaign is created, you will get the exact impressions.": "It is an approximate value, because it depends on the days and rules you choose in your campaign. Once the campaign is created, you will get the exact spots.",
    "Hours of Operation": "Hours of Operation",
    "Lit all day every day": "Lit all day every day",
    "Allowed ad format": "Allowed ad format",
    "Ad rotated": "Ad rotated",
    "to the right": "to the right",
    "to the left": "to the left",
    "Image": "Image",
    "Video": "Video",
    "HTML": "HTML",
    "Quality": "Quality",
    "Orientation": "Orientation",
    "Floor elevation": "Floor elevation",
    "meters": "meters",
    "Viewing distance": "Viewing distance",
    "Target": "Target",
    "Restrictions": "Restrictions",
    "Admit any ad or spot that respects everyday rules": "Admit any ad or spot that respects everyday rules",
    "Live cameras": "Live cameras",
    "Traffic": "Traffic",
    "Audience": "Audience",
    "You have not configured a camera to measure the audience": "You have not configured a camera to measure the audience",
    "Show original": "Show original",
    "Translate to": "Translate to",
    "List of days": "List of days",
    "Details": "Details",
    "Daily audience": "Daily audience",
    "Weekly audience": "Weekly audience",
    "Monthly audience": "Monthly audience",
    "Map": "Map",
    "Multiple screens": "Multiple screens",
    "Without restrictions": "Without restrictions",
    "Cost per day": "Cost per day",
    "CPM impacts": "CPM impacts",
    "CPM spots": "CPM spots",
    "Cost per thousand impacts": "Cost per thousand impacts",
    "Cost per thousand spot": "Cost per thousand spot",

    // OnboardingComponent
    "Interest targeting": "Interest targeting",
    "Automobiles": "Automobiles",
    "Show all": "Show all",
    "Business": "Business",
    "Services": "Services",
    "Entertainment": "Entertainment",
    "Education": "Education",
    "Banking": "Banking",
    "Transport": "Transport",
    "Government": "Government",
    "Hospitality": "Hospitality",
    "Food": "Food",
    "Health & Beauty": "Health & Beauty",
    "Temperature": "Temperature",
    "Humidity": "Humidity",
    "Estimated audience for this location": "Estimated audience for this location",
    "Average per print": "Average per print",
    "Estimated traffic": "Estimated traffic",
    "Active screens": "Active screens",
    "Estimated audience": "Audience",
    "Screens with cameras measuring traffic and people": "Screens with cameras measuring traffic and people",

    // Map
    "Remember to zoom out the map to see more results": "Remember to zoom out the map to see more results",
    "Only screens that are within the visible area of the map will be displayed.": "Only screens that are within the visible area of the map will be displayed.",
    "If the screen is at its maximum capacity, at least your ad will be displayed": "If the screen is at its maximum capacity, at least your ad will be displayed",
    "times per hour. Otherwise it will display more": "times per hour. Otherwise it will display more",
    "Recommended duration": "Recom. duration",
    "An extra may be charged in the event that your ad times out on some screens": "An extra may be charged in the event that your ad times out on some screens",
    "Location, street or city": "Location, street or city",
    "Search on move": "Search on move",
    "Programmatic advanced filters": "Programmatic advanced filters",
    "Getting results": "Getting results",
    "Multiple faces screen": "Mirrored screen",
    "sides": "sides",
    "Circuit of n_screen screens" : "Circuit of n_screen screens",
    "Selection filters": "Selection filters",
    "Map filters": "Map filters",
    "Countries": "Countries",
    "Results are filtered by country. You can" : "Results are filtered by country. You can",
    "remove that filter and try again." : "remove that filter and try again.",
    "Search by place": "Search by place",
    "Refinate your search": "Refinate your search",

    // ScreenTypePreviewComponent
    "screens with": "screens with",
    "mixed content": "mixed content",
    "Exceeded on some screen": "Exceeded on some screen",
    "of": " of ",
    "The spot will be seen n_days days, with n_impressions impressions per days" : "This material will be seen n_days days, with n_impressions spots per day",
    "Unable to distribute due to lack of remaining prints" : "Unable to distribute due to lack of remaining spots",
    "Remaining to consume": "Remaining to consume",
    "Impressions details": "Spots details",
    "Consumed": "Consumed",
    "To consumed": "To consumed",
    "Total material": "Total material",
    "Total display": "Total display",
    "Investment detail": "Investment detail",
    "Remaining": "Remaining",
    "Total on display": "Total on display",
    "Total on version": "Total on version",
    "Total material subsidized": "Total material subsidized",
    "day": "day",
    "days": "days",
    "You must select at least one day": "You must select at least one day",

    // Displays
    "Square": "Square",
    "Vertical": "Vertical",
    "Horizontal": "Horizontal",
    "Super horizontal": "Super horizontal",
    "The email entered already exists": "The email entered already exists",
    "Enter a valid email": "Enter a valid email",
    "The name must be less than 255 characters.": "The name must be less than 255 characters.",
    "The last name must be less than 255 characters.": "The last name must be less than 255 characters.",
    "The company name already exists. Please enter another.": "The company name already exists. Please enter another.",
    "Incorrect username or password": "Incorrect username or password",
    "Fill in all fields": "Fill in all fields",
    "We cant find that email in our database, try another one.": "We can't find that email in our database, try another one.",
    "Incorrect security code": "Incorrect security code",
    "The code must have 6 digits": "The code must have 6 digits",
    "Your account is not verified": "Your account is not verified",
    "This account has already been verified.": "This account has already been verified.",
    "Unexpected error": "Unexpected error",
    "Too many attempts": "Too many attempts",
    "Account is not activated": "Your account is not activated.",
    "Password changed": "You password has changed, please log in again",

    // Common
    "Good!": "Good!",
    "Changes successfully saved": "Changes successfully saved",
    "Loading": "Loading",
    "Go back": "Go back",
    "Edit": "Edit",
    "Close": "Close",
    "continue": "continue",
    "edit": "edit",
    "Create": "Create",
    "Apply": "Apply",
    "Select": "Select",
    "Categories": "Categories",
    "An error occurred, please try again": "An error occurred, please try again",
    "Token expired, please login again": "Token expired, please login again",
    "Reload": "Reload",
    "Cancel": "Cancel",
    "Contact us": "Contact us",
    "Remove": "Remove",
    "Add": "Add",
    "Can not be blank": "Can not be empty",
    "Select a category": "Select a category",
    "You must select a category": "You must select a category",
    "All screens must have at least some content": "All screens must have at least some content.",
    "You can not exeed 200 chars in this field": "You can not exeed 200 chars in this field",
    "seconds": "seconds",
    "persons": "persons",
    "Day": "Day",
    "unknown": "Unknown",
    "Print": "spot",
    "Prints": "spots",
    "per print": "p/spot",
    "Results": "Results",
    "screen": "screen",
    "screens": "screens",
    "added": "added",
    "Show all screens": "Show all screens",
    "Hide Screens": "Hide Screens",
    "All": "All",
    "Some": "Some",
    "Payment method": "Payment method",
    "Campaign": "Campaign",
    "Bank data": "Bank data",
    "All": "All",
    "Billing Country": "Billing Country",
    "Select country": "Select country",
    "Education": "Education",
    "Entertainment": "Entertainment",
    "Services": "Services",
    "If you choose a DSP that is not LatinAd, you will only be able to make purchases in USD through an international invoice.": "If you choose a DSP that is not LatinAd, you will only be able to make purchases in USD through an international invoice.",

    // audience Dats Why
    "Female": "Female",
    "Male": "Male",
    "from 0 to 14": "from 0 to 14",
    "from 15 to 19": "from 15 to 19",
    "from 20 to 29": "from 20 to 29",
    "from 30 to 44": "from 30 to 44",
    "from 45 to 54": "from 45 to 54",
    "more than 55": "more than 55",
    "Data obtained with the integration of": "Data obtained with the integration of",
    "Audience Hyper-Insights that help you plan, monitor, and analyze Out of Home sites and campaigns to maximize ROI.": "Audience Hyper-Insights that help you plan, monitor, and analyze Out of Home sites and campaigns to maximize ROI.",
    "General":"General",
    "By day of week":"By day of week",
    "Quantitative audience": "Quantitative audience",
    "Qualitative audience": "Qualitative audience",
    "Audience": "Audience",
    "Audience based on third-party measurement data":"Audience based on third-party measurement data.",
    "Third audience": "Third party audience",
    "Dats Why audience": "Dats Why Audience",
    "Audience summary": "Audience summary",
    "Data per month in the radius of the screen location": "Data per month in the radius of the screen location",
    "Total vehicle impacts": "Audience total vehicle impacts audience",
    "Unique vehicle impacts": "Unique vehicle impacts audience",
    "Frequency of unique people": "Frequency of unique people",
    "Total people impacts":"Audience total people impacts",
    "Impacts on unique people": "Impacts on unique people",
    "Average exposure in seconds": "Average exposure in seconds",

    // 404
    "We look everywhere for the page": "We look everywhere for the page",
    "(404 error)": "(404 error)",
    "Go to LatinAd": "Go to LatinAd",

    // Modal of currency - billing Conuntry
    "Language & Currency configuration": "Language & Currency configuration",
    "Language": "Language",
    "Billing Country": "Billing Country",
    "Apply settings": "Apply settings",
    "More": "More",
    "Dates": "Dates",
    "You will receive an international invoice without local taxes and the payment must be in USD (American Dollars) internationally.": "You will receive an international invoice without local taxes and the payment must be in USD (American Dollars) internationally. If there are expenses for WithHolding Taxes, they will be charged as an extra item and will be borne by the advertiser.",
    "International billing": "International billing",

    // POI - Points of interest
    "Bar & Coffee": "Bar & Coffee",
    "Beauty care": "Beauty care",
    "Education": "Education",
    "Entertainment": "Entertainment",
    "Finance": "Finance",
    "Health": "Health",
    "Institutions": "Institutions",
    "Restaurants": "Restaurants",
    "Services": "Services",
    "Shops": "Shops",
    "Transportation": "Transportation",
    "Vehicles": "Vehicles",

    // Info discount volume
    "2-7": " 2 to 7 days",
    "8-15": "8 to 15 days",
    "16-28": "16 to 28 days",
    "29-60": "from 29 days",
    "Volume discount": "Volume discount",
    "Discount applied": "Discount applied",
    "Increase n_diff_discount your campaign to access higher discounts": "Increase n_diff_discount your campaign to access higher discounts",
    // subtype
    "Sub Type of screen": "Sub Type of screen",
    "subtype_301": "Billboards",
    "subtype_302": "Urban Panels",
    "subtype_303": "Bus Shelters",
    "subtype_205": "Mall",
    "subtype_101": "Airports",
    "subtype_105": "Subway",
    "subtype_106": "Train Stations",
    "subtype_201": "Fueling Stations",
    "subtype_202": "Convenience Stores",
    "subtype_203": "Grocery",
    "subtype_207": "Pharmacies",
    "subtype_401": "Gyms",
    "subtype_402": "Salons",
    "subtype_501": "Doctor's Offices",
    "subtype_601": "Schools",
    "subtype_602": "Colleges and Universities",
    "subtype_701": "Office Buildings",
    "subtype_801": "Recreational Locations",
    "subtype_804": "Bars",
    "subtype_805": "Casual Dining",
    "subtype_1001": "Banks",
    "subtype_102": "Bus",
    "subtype_103": "Taxi",
    "subtype_104": "Taxi and Rideshare Top",
    "subtype_10202": "Terminal",
    "subtype_204": "Liquor Stores",
    "subtype_806": "QSR",

    //External DSP
    "info_dsp": "When selecting a DSP other than Latinad, the deal creation process may take between 3 to 6 hours to become active. Afterward, they will be ready for implementation on the selected DSP.",
    "info_payment_first": "By selecting a DSP other than Latinad",
    "info_payment_second":", the campaign payment cannot be processed within this site; it must be done on the selected platform.",
    "info_payment_third": "The amount to invest in the campaign created in Latinad may not include the commissions of the chosen DSP, which may require the total assigned value to be higher to cover them.",

    // Verified agencies
    "Verified agency": "Verified agency",
    "Limited functionality": "Limited functionality",
    "Verified agencies text": "To access all platform-exclusive features, agencies need to be verified. For more information, please visit:",
    "The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.": "The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.",
    "Company is not verified and has more than $5000 USD in campaigns this month": "It's not possible to create this campaign. The company is not verified and has spend more than $5000 USD in campaigns this month",
    "The maximum budget for non verified agencies is 5000 USD a month": "The maximum budget for non verified agencies is 5000 USD a month.",

    //Bundles
    "Sales bundles": "Sales bundles",
    "Sales bundle details": "Sales bundle details",
    "This screen belongs to the following bundles.": "This screen belongs to the following bundles.",
    "Number of screens": "Number of screens",
    "Traditional price": "Traditional price",
    "Programmatic price": "Programmatic price",
    "month": "month",
    "Delete to cart": "Delete to cart",
    "Number of screens": "Number of screens",
    "Type of bundle": "Type of bundle",
    "Displays of bundle": "Displays of bundle",
    "You can buy it only in a bundle (You will buy the entire bundle)": "You can buy it only in a bundle (You will buy the entire bundle)",
    "You can buy it individually or as a sales bundle (You will buy the entire bundle)": "You can buy it individually or as a sales bundle (You will buy the entire bundle)",
    "This screen belongs to the following packages.": "This screen belongs to the following packages.",
    "When deleting this screen, the packages to which it belongs, as well as the screens associated with those packages, will be automatically removed from the cart.": "When deleting this screen, the packages to which it belongs, as well as the screens associated with those packages, will be automatically removed from the cart.",
    "Belongs to the following packages": "Belongs to the following packages",

    //Transfer of spots
    "Transfer of spots": "Transfer of spots",
    "Screen owners": "Screen owners",
    "Subtracted spots": "Subtracted spots",
    "Added spots": "Added spots",
    "Initial budget": "Initial budget",
    "Initial spots": "Initial spots",
    "Available credit": "Available credit",
    "Apply changes": "Apply changes",
    "Pending spots": "Pending spots",
    "Budget": "Budget",
    "You have available credit. You must use it to continue distributing.": "You have available credit. You must use it to continue distributing.",
    "Consumed credit. You can apply changes and edit. The remaining credit (n_credite) cannot be used to purchase 1 spot.": "Consumed credit. You can apply changes and edit. The remaining credit (n_credite) cannot be used to purchase 1 spot.",

    // Audience provider info
    "Audience data for these screens provided by": "Audience data for these screens provided by",
    "Audience data for this screen provided by": "Audience data for this screen provided by",
    "display owner": "the display owner",
    "There is no audience data for this screen": "There is no audience data for this screen",
    "There is no audience data for these screens": "There is no audience data for these screens",

    // Rules component
    "Rules": "Rules",
    "Days of the week": "Days of the week",
    "Create rules": "Create rules",
    "Rules in campaign": "Rules in campaign",
    "Name of the rule": "Name of the rule",
    "Create new rules": "Create new rules",
    "ruleAppliedAllVersions": "Rule applied to all screens: {{versions}}",
    "ruleAppliedSomeVersions": "Rule applied to all screens in {{versions}} and others, expand group to see",
    "ruleAppliedSomeRelations": "Rule applied to some screens. Expand group to see",
    "New rules": "New rules",
    "Apply rules": "Apply rules",
    "Remove rule": "Remove rule",
    "Set up rules in this display": "Set up rules in this display",
    "Set up rules in this spot": "Set up rules in this ad",
    "displays": "displays",
    "display": "display",
    "spots": "spots",
    "Spots": "Spots",
    "Rules created": "Rules created",
    "Applying in display": "Applying in display",
    "Applying in spot": "Apply rules to ad",
    "Applying in group": "Applying in group",
    "Applying in campaign": "Applying in campaign",
    "There are no general rules for this group": "There are no general rules for this group",
    "There are no general rules for this display": "There are no general rules for this display",
    "There are no rule for this spot": "There are no rule for this spot",
    "Without rules in group": "Without rules in group",
    "Without rules in display": "Without rules in display",
    "Without rules": "Without rules",
    "Rule(s) applied": "Rule(s) applied",
    "Other applied rule(s)": "Other applied rule(s)",
    "throughout the group": "throughout the group",
    "throughout the display": "throughout the display",
    "Content name": "Content name",
    "Spot resolution": "Spot resolution",
    "Ad name": "Ad name",
    "Not rules created": "Not rules created",
    "The name of the rule already exists": "The name of the rule already exists",
    "The name of the rule is required": "The name of the rule is required",
    "Applied rule": "Applied rule",
    "Save": "Save",
    "Save and apply": "Save and apply",
    "You are about to permanently remove this rule from the list. This action cannot be undone": "You are about to permanently remove this rule from the list. This action cannot be undone",

    // Screens Group
    "Removing this spot will redistribute the impressions among the remaining spots.": "Removing this spot will redistribute the impressions among the remaining spots.",
    "The ad will be removed from the screen, and the spots will be redistributed among the other versions. You can add it again from the previous Ads step or by clicking the Add Ad button.": "The ad will be removed from the screen, and the spots will be redistributed among the other versions. You can add it again from the previous Ads step or by clicking the 'Add Ad' button.",
    "You are going to remove this screen from the campaign": "By removing this screen, it will no longer be in the campaign. You can add it back from the map step.",
    "Are you sure you want to delete?" : "Are you sure you want to delete?",
    "You are going to remove this group of screens from the campaign": "You are going to remove this group of screens from the campaign",
    "Remove group of screens": "Remove group of screens",
    "By removing the group, all screens within this group will be removed from the campaign.": "By removing the group, all screens within this group will be removed from the campaign.",
    "To delete need at least two group in the campaign": "To delete need at least two group in the campaign",
    "Remove group": "Remove group",
    "Investment": "Investment",
    "Investment of content": "Investment of content",
    "Content version": "Content version",
    "Distribute by": "Distribute by",
    "Total investment for this screen": "Total investment for this screen",
    "None": "None",
    "Countries": "Countries",
    "This screen does not accept contents of type": "This screen does not accept contents of type",
    "image": "imagen",
    "video": "video",
    "html": "html",
    "Balance impressions": "Balance impressions",
    "Equalize the spots across all screens, at the exact amount that keeps the campaign budget up to date.": "Equalize the spots across all screens, at the exact amount that keeps the campaign budget up to date.",
    "You're about to equalize the spots across all screens, which will modify any previous spot distribution. Do you wish to continue?": "You're about to equalize the spots across all screens, which will modify any previous spot distribution. Do you wish to continue?",
    "Confirm redistribution": "Confirm redistribution",
    "Impressions will be distributed equally depending on the updated budget": "Impressions will be distributed equally depending on the updated budget",
    "When setting the amount for (n_type), it will not be modified during the automatic budget redistribution. It can be adjusted manually at any time.": "When setting the amount for {{n_type}}, it will not be modified during the automatic budget redistribution. It can be adjusted manually at any time.",
    "If the amount for (n_type) is not fixed, it may be modified during the automatic budget redistribution. It can also be adjusted manually at any time.": "If the amount for {{n_type}} is not fixed, it may be modified during the automatic budget redistribution. It can also be adjusted manually at any time.",
    "this display": "this display",
    "this spot": "this spot",
    "this displays group": "this displays group",

    // ContentSpecificationAlertComponent
    "Some content does not meet the specifications on one or more screens in this group": "Some content does not meet the specifications on one or more screens in this group.",
    "Some content does not meet the specifications on this screen": "Some content does not meet the specifications on this screen",
    "Some content does not meet the specifications on one or more screens": "Some content does not meet the specifications on one or more screens",

    // CheckCompanyForUserComponent
    "Different companies": "Different companies",
    "The company logged into the system (n_company) does not match the one you have in the DSP (n_company_check). You will be redirected to avoid errors.": "The company logged into the system (n_company) does not match the one you have in the DSP (n_company_check). You will be redirected to avoid errors.",

    //Modal new version
    "DISCOVER": "DISCOVER",
    "the new way to": "the new way to",
    "launch your": "launch your",
    "CAMPAIGN!": "CAMPAIGN!",
    "Faster": "Faster",
    "Smarter": "Smarter",
    "Easier to use": "Easier to use",
    "TRY THE NEW": "TRY THE NEW",
    "VERSION NOW!": "VERSION NOW!",
    "View guide": "View guide"
}
